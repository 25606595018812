import { Link, useParams } from "react-router-dom";
import c from "classnames";
import Txs from "../Txs";
import Finder from "../../components/Finder";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import WithFetch from "../../HOCs/WithFetch";
import s from "./Block.module.scss";
import { fromISOTime } from "../../scripts/utility";
import { useCurrentChain } from "../../contexts/ChainsContext";

const TerraBlock = () => {
  const { height } = useParams();
  const { chainID } = useCurrentChain();

  const url = `/v1/blocks/${height}?chainId=${chainID}`;

  const heightButton = (height: number) => (
    <span className={s.height}>
      <span>{height}</span>
      <Link to={`../blocks/${+height - 1}`}>
        <i className="material-icons">chevron_left</i>
      </Link>
      <Link to={`../blocks/${+height + 1}`}>
        <i className="material-icons">chevron_right</i>
      </Link>
    </span>
  );

  return (
    <WithFetch url={url} loading={<Loading />}>
      {blockData =>
        blockData ? (
          <>
            <h2 className="title">
              Block<span>#{height}</span>
            </h2>
            <div className={c(s.list, s.blockInfo)}>
              <div className={s.row}>
                <div className={s.head}>Chain ID</div>
                <div className={s.body}>{blockData.chainId}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Block height</div>
                <div className={s.body}>{heightButton(blockData.height)}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Timestamp</div>
                <div className={s.body}>{fromISOTime(blockData.timestamp)}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Transactions</div>
                <div className={s.body}>{blockData.txs.length}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Proposer</div>
                <div className={s.body}>
                  <Finder
                    q={"validator"}
                    v={blockData.proposer.operatorAddress}
                  >
                    {blockData.proposer.moniker}
                  </Finder>
                </div>
              </div>
            </div>

            <Txs txs={blockData.txs} />
          </>
        ) : (
          <NotFound keyword={height} />
        )
      }
    </WithFetch>
  );
};

export default TerraBlock;
