import { get, isEmpty } from "lodash";
import { Tx, TxInfo } from "@terra-money/terra.js";
import FlexTable from "../../components/FlexTable";
import Info from "../../components/Info";
import Card from "../../components/Card";
import Finder from "../../components/Finder";
import { fromNow, sliceMsgType } from "../../scripts/utility";
import TxAmount from "../Tx/TxAmount";
import { transformTx } from "../Tx/transform";
import { useCurrentChain, useIsClassic } from "../../contexts/ChainsContext";
import s from "./Txs.module.scss";
import { LCDClient } from "@terra-money/feather.js";
import * as crypto from "crypto";

const useLCD = (chainID: string) => {
  if (chainID !== "phoenix-1" && chainID !== "pisco-1") {
    return new LCDClient({});
  }
  return undefined;
};

const getType = (tx: Tx.Data) => {
  const message = tx.body.messages[0]["@type"].split(/[\s.]+/);

  return message[1] + "/" + message[message.length - 1];
};

const getTxHash = (txstring: string) => {
  const s256Buffer = crypto
    .createHash("sha256")
    .update(Buffer.from(txstring, "base64"))
    .digest();
  const txbytes = new Uint8Array(s256Buffer);
  return Buffer.from(txbytes.slice(0, 32)).toString(`hex`).toUpperCase();
};

const getRow = (
  response: TxInfo,
  chainID: string,
  isClassic: boolean,
  lcdClient?: LCDClient,
  height?: string,
  timestamp?: string
) => {
  if (lcdClient) {
    let txhash;
    let finalTx;
    let fee;

    try {
      finalTx = lcdClient!.tx.decode(response.toString()).toData();
      fee = finalTx.auth_info.fee.amount[0];
      txhash = getTxHash(response.toString());
    } catch (e) {
      txhash = "This transaction is not supported.";
      console.log(e);
      return [];
    }

    return [
      <span>
        <Finder q="tx" v={txhash}>
          {txhash}
        </Finder>
      </span>,
      <span className="type">{sliceMsgType(getType(finalTx!))}</span>,
      <span>
        {isEmpty(fee) ? (
          `0 ${isClassic ? "Lunc" : "Luna"}`
        ) : (
          <TxAmount amount={fee.amount} denom={fee.denom} />
        )}
      </span>,
      <span>{height}</span>,
      <span>{fromNow(timestamp!.toString())}</span>
    ];
  } else {
    const transformed = transformTx(response, chainID);
    let { txhash, tx, height, timestamp } = transformed;
    const fee = get(tx, `value.fee.amount[0]`);

    return [
      <span>
        <Finder q="tx" v={txhash}>
          {txhash}
        </Finder>
      </span>,
      <span className="type">{sliceMsgType(tx?.value?.msg[0].type)}</span>,
      <span>
        {isEmpty(fee) ? (
          `0 ${isClassic ? "Lunc" : "Luna"}`
        ) : (
          <TxAmount amount={fee.amount} denom={fee.denom} />
        )}
      </span>,
      <span>{height}</span>,
      <span>{fromNow(timestamp.toString())}</span>
    ];
  }
};

const Txs = ({
  txs,
  height,
  timestamp
}: {
  txs: TxInfo[];
  height?: string;
  timestamp?: string;
}) => {
  const head = [`TxHash`, `Type`, `Fee`, `Height`, `Time`];
  const { chainID } = useCurrentChain();
  const isClassic = useIsClassic();
  const lcdClient = useLCD(chainID);

  return (
    <div className={s.tableContainer}>
      {txs.length ? (
        <FlexTable
          head={head}
          body={txs.map(tx =>
            getRow(tx, chainID, isClassic, lcdClient, height, timestamp)
          )}
        />
      ) : (
        <Card>
          <Info icon="info_outline" title="">
            No more transactions
          </Info>
        </Card>
      )}
    </div>
  );
};

export default Txs;
