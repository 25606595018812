import { useCurrentChain, useFCDURL } from "../contexts/ChainsContext";

export const RefetchOptions = {
  DEFAULT: /* onMount, onFocus */ {},
  INFINITY: { staleTime: Infinity, retry: false, refetchOnWindowFocus: false }
};

const LAZY_LIMIT = 999;

/* params */
export const Pagination = {
  "pagination.limit": String(LAZY_LIMIT)
};

export const useGetQueryURL = (url: string) => {
  const { lcd, useLCD } = useCurrentChain();
  const fcdURL = useFCDURL();
  const queryURL = useLCD ? lcd : fcdURL;

  return queryURL + url;
};
