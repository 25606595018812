import { Link, useParams } from "react-router-dom";
import c from "classnames";
import Txs from "../Txs";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import WithFetch from "../../HOCs/WithFetch";
import s from "./Block.module.scss";
import { useCurrentChain } from "../../contexts/ChainsContext";

const FeatherBlock = () => {
  const { height } = useParams();
  const doesLCDExist = useCurrentChain().lcd ? true : false;

  const url = `/cosmos/base/tendermint/v1beta1/blocks/${height}`;

  const heightButton = (height: number) => (
    <span className={s.height}>
      <span>{height}</span>
      <Link to={`../blocks/${+height - 1}`}>
        <i className="material-icons">chevron_left</i>
      </Link>
      <Link to={`../blocks/${+height + 1}`}>
        <i className="material-icons">chevron_right</i>
      </Link>
    </span>
  );
  return (
    <WithFetch lcd={doesLCDExist} url={url} loading={<Loading />}>
      {blockData =>
        blockData ? (
          <>
            <h2 className="title">
              Block<span>#{height}</span>
            </h2>
            <div className={c(s.list, s.blockInfo)}>
              <div className={s.row}>
                <div className={s.head}>Chain ID</div>
                <div className={s.body}>{blockData.block.header.chain_id}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Block height</div>
                <div className={s.body}>
                  {heightButton(blockData.block.header.height)}
                </div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Timestamp</div>
                <div className={s.body}>{blockData.block.header.time}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Transactions</div>
                <div className={s.body}>{blockData.block.data.txs.length}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Proposer</div>
                {/*  <div className={s.body}>
            <Finder
              q={"validator"}
              v={blockData.proposer.operatorAddress}
            >
              {blockData.proposer.moniker}
            </Finder>
          </div> */}
              </div>
            </div>

            <Txs
              txs={blockData.block.data.txs}
              height={height}
              timestamp={blockData.block.header.time}
            />
          </>
        ) : (
          <NotFound keyword={height} />
        )
      }
    </WithFetch>
  );
};

export default FeatherBlock;
