import { useParams } from "react-router-dom";
import NotFound from "../../components/NotFound";
import Delegations from "./Delegations";
import Undelegations from "./Undelegations";
import CopyAddress from "./CopyAddress";
import TokenBalance from "./TokenBalance";
import Txs from "./Txs";
import { bech32 } from "bech32";
import { useCurrentChain } from "../../contexts/ChainsContext";

const Account = () => {
  const { address = "" } = useParams();
  const { prefix } = useCurrentChain();

  const checkPrefixAndLength = (
    prefix: string,
    data: string,
    length: number
  ) => {
    try {
      const val = bech32.decode(data);
      return val.prefix === prefix && data.length === length;
    } catch (e) {
      return false;
    }
  };

  const validateAccount = (address: string) => {
    const length = 39 + prefix.length;
    return checkPrefixAndLength(prefix, address, length);
  };

  return validateAccount(address) ? (
    <>
      <h2 className="title">Account Detail</h2>

      <CopyAddress>{address}</CopyAddress>

      <TokenBalance address={address} />
      <Delegations address={address} />
      <Undelegations address={address} />
      <Txs address={address} />
    </>
  ) : (
    <NotFound keyword={address} />
  );
};

export default Account;
