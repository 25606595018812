import { useCurrentChain } from "../../contexts/ChainsContext";
import TerraBlock from "./TerraBlock";
import FeatherBlock from "./FeatherBlock";
import CosmosBlock from "./CosmosBlock";

const isTerra = (chainID: string) => {
  return chainID === "phoenix-1" || chainID === "pisco-1";
};

const isFeather = (chainID: string) => {
  return chainID === "feath-1";
};

const Block = () => {
  const { chainID } = useCurrentChain();
  const isTerraChain = isTerra(chainID);
  const isFeatherChain = isFeather(chainID);

  return (
    <>
      {isTerraChain && <TerraBlock />}
      {isFeatherChain && <FeatherBlock />}
      {!isTerraChain && !isFeatherChain && <CosmosBlock />}
    </>
  );
};

export default Block;
